import PropTypes from 'prop-types'
import React, { useState } from 'react'
import axios from 'axios'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import mbs from '../images/mbs.jpg'
import waterride from '../images/waterride.jpg'
import PlayStoreImg from '../images/playstore.png'

const Main = (props) => {
  const [values, setValues] = useState(
    Object.assign({
      name: '',
      email: '',
      message: '',
    })
  )
  const [errors, setErrors] = useState(false)
  const [status, setStatus] = useState(false)

  const lablelStyle = {
    fontStyle: 'italic',
    textTransform: 'none',
    color: 'gold',
    padding: '10px 0px',
  }
  const handleChange = (e) => {
    const { name, value } = e.target

    setValues({
      ...values,
      [name]: value,
    })

    setErrors(false)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    console.log('fff', values)
    if (values && (!values.name || !values.email || !values.message)) {
      setErrors(true)
    } else {
      axios({
        method: 'post',
        url: 'https://us-central1-mb-be-c9091.cloudfunctions.net/app/enquiry',
        data: values,
      })
        .then((resp) => {
          if (resp) {
            setStatus(true)
            setTimeout(function () {
              window.location.replace('/')
            }, 1000)
          }
        })
        .catch((err) => console.log(err))
    }
  }

  let close = (
    <div
      className="close"
      onClick={() => {
        props.onCloseArticle()
      }}
    ></div>
  )

  return (
    <div
      ref={props.setWrapperRef}
      id="main"
      style={props.timeout ? { display: 'flex' } : { display: 'none' }}
    >
      <article
        id="intro"
        className={`${props.article === 'intro' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">who we are</h2>
        {/* <span className="image main">
          <img src={pic01} alt="" />
        </span> */}
        <p>
          We are Lite Games, a game development company focused on developing
          the best mobile games for you. We were established in the year 2020 in
          India and We are a small team of experienced designers and developers.
        </p>
        {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus
          rutrum facilisis. Class aptent taciti sociosqu ad litora torquent per
          conubia nostra, per inceptos himenaeos. Etiam tristique libero eu nibh
          porttitor fermentum. Nullam venenatis erat id vehicula viverra. Nunc
          ultrices eros ut ultricies condimentum. Mauris risus lacus, blandit
          sit amet venenatis non, bibendum vitae dolor. Nunc lorem mauris,
          fringilla in aliquam at, euismod in lectus. Pellentesque habitant
          morbi tristique senectus et netus et malesuada fames ac turpis
          egestas. In non lorem sit amet elit placerat maximus. Pellentesque
          aliquam maximus risus, vel sed vehicula.
        </p> */}
        {close}
      </article>

      <article
        id="work"
        className={`${props.article === 'work' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">OUR GAMES</h2>
        <span className="image main">
          <h3>MOUNTAIN BUS STIMULATOR:INDIA</h3>
          <br />
          <img src={mbs} alt="" width="100%" />
        </span>
        <div className="blink" style={{ margin: 'auto', width: '40%' }}>
          <a
            style={{ cursor: 'pointer', border: 'none' }}
            href="https://play.google.com/store/apps/details?id=com.litegames.MountainbussimulatorIndia"
            target="blank"
          >
            <img src={PlayStoreImg} width="100%" />
          </a>
        </div>
        <p>
          Drive across the best routes in India and enjoy the realistic driving
          experience. Transport passengers from one place to another and earn
          rewards.enjoy the beauty of india. Realistic vehicle physics and our
          buses will make you an expert in driving. Choose your bus, route and
          start driving.
          <br />
          <br />
          <h5>Top features</h5>
          - 4 easy different controls
          <br />
          - Indian local buses are available
          <br />
          - 12+ bus
          <br />
          - More than 7 realistic routes
          <br />
          - High quality 3d graphics
          <br />
          - Realistic vehicle physics
          <br />
        </p>
        <h3>WATER RIDE</h3>
        <br />
        <img src={waterride} alt="" width="100%" />
        <div className="blink" style={{ margin: 'auto', width: '40%' }}>
          <a
            style={{ cursor: 'pointer', border: 'none' }}
            href="https://play.google.com/store/apps/details?id=com.litegames.waterride"
            target="blank"
          >
            <img src={PlayStoreImg} width="100%" />
          </a>
        </div>
        <p>
          <br />
          Complete the levels by collecting the coins and reach the finish line
          without hitting any obstacles. Drag your finger to move, collect more
          coins to get more stars and unlock levels.
        </p>
        {close}
      </article>

      <article
        id="about"
        className={`${props.article === 'about' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">About</h2>
        <span className="image main">{/* <img src={pic03} alt="" /> */}</span>
        <p>We develop litegames.</p>
        {close}
      </article>

      <article
        id="contact"
        className={`${props.article === 'contact' ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">Contact</h2>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={(e) => handleChange(e)}
            />
            {errors.name && (
              <label style={lablelStyle}>Please enter you name</label>
            )}
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              id="email"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              rows="4"
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>
          <div>
            {errors && (
              <label style={lablelStyle}>
                Please fill up the form completely
              </label>
            )}
          </div>
          <ul className="actions">
            <li>
              <input type="submit" value="Send Message" className="special" />
            </li>
            <li>
              <input type="reset" value="Reset" />
            </li>
          </ul>
        </form>
        <div>
          {status && (
            <label style={lablelStyle}>Thank you for your feedback</label>
          )}
        </div>
        {/* <ul className="icons">
          <li>
            <a
              href="https://twitter.com/HuntaroSan"
              className="icon fa-twitter"
            >
              <span className="label">Twitter</span>
            </a>
          </li>
          <li>
            <a href="https://codebushi.com" className="icon fa-facebook">
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a href="https://codebushi.com" className="icon fa-instagram">
              <span className="label">Instagram</span>
            </a>
          </li>
        </ul> */}
        {close}
      </article>
    </div>
  )
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
